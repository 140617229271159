import "./src/styles/main.scss";

// Scroll Restoration Fix
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // List of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/`, `/legal/impressum`, `/legal/privacy-policy`];
  // If the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0);
  }
  return false;
};
